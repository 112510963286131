"use strict";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    "strict": process.env.NODE_ENV !== "production",
    "state": {
        "settings": {
            "collections": {},
            "configs": {},
            "displayOrders": {},
            "fields": {},
            "managements": {},
            "menuItems": {},
            "modules": {},
            "navigations": {},
        },
        "master": localStorage.getItem("master"),
        "database": {},
        "transceiver": {},
        "cart": localStorage.getItem("cart"),
    },
    "getters": {
        "getCollections": state => state.settings.collections,
        "getConfigs": state => state.settings.configs,
        "getDisplayOrders": state => state.settings.displayOrders,
        "getFields": state => state.settings.fields,
        "getManagements": state => state.settings.managements,
        "getMenuItems": state => state.settings.menuItems,
        "getModules": state => state.settings.modules,
        "getNavigations": state => state.settings.navigations,
        "getMaster": state => state.master,
        "getDatabase": state => state.database,
        "getTransceiver": state => state.transceiver,
        "getCart": state => state.cart,
    },
    "actions": {
        setSettings({ commit }, data) {
            commit("setSettings", data);
        },
        setDisplayOrders({ commit }, data) {
            commit("setDisplayOrders", data);
        },
        setMaster({ commit }, data) {
            commit("setMaster", data);
        },
        setDatabase({ commit }, data) {
            commit("setDatabase", data);
        },
        setTransceiver({ commit }, data) {
            commit("setTransceiver", data);
        },
        setCart({ commit }, data) {
            commit("setCart", data);
        },
    },
    "mutations": {
        setSettings(state, data) {
            state.settings = data;
        },
        setDisplayOrders(state, data) {
            state.settings.displayOrders = data;
        },
        setMaster(state, data) {
            state.master = data;
            localStorage.setItem("master", data);
        },
        setDatabase(state, data) {
            state.database = data;
        },
        setTransceiver(state, data) {
            state.transceiver = data;
        },
        setCart(state, data) {
            state.cart = data;
            if (Object.keys(JSON.parse(data)).length) {
                localStorage.setItem("cart", data);
            }
            else {
                localStorage.removeItem("cart");
            }
        },
    },
});
