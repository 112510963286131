"use strict";

const title = process.env.VUE_APP_TITLE;

export default {
    "/": {
        "name": "standard",
        "authentication": "StandardFrame",
        "component": "StandardFrame",
        "page": {
            "cart": {
                "component": "EcCart",
                "detail": true,
                "requiresAuth": false,
                "title": title,
            },
            "eventNews": {
                "component": "EcNews",
                "detail": true,
                "requiresAuth": false,
                "title": title,
            },
            "item": {
                "component": "EcItemDetail",
                "detail": true,
                "requiresAuth": false,
                "title": title,
            },
            "itemCategory": {
                "component": "EcItemList",
                "detail": true,
                "requiresAuth": false,
                "title": title,
            },
            "itemCategoryList": {
                "component": "EcItemCategoryList",
                "requiresAuth": false,
                "title": title,
            },
            "news": {
                "component": "EcNews",
                "detail": true,
                "requiresAuth": false,
                "title": title,
            },
            "order": {
                "component": "InputList",
                "requiresAuth": false,
                "input": true,
                "confirm": true,
                "complete": true,
                "title": title,
            },
            "reservationCalendar": {
                "component": "ReservationCalendar",
                "requiresAuth": true,
                "title": title,
            },
            "search": {
                "component": "EcItemList",
                "requiresAuth": false,
                "title": title,
            },
            "specialNews": {
                "component": "EcNews",
                "detail": true,
                "requiresAuth": false,
                "title": title,
            },
            "terms": {
                "component": "EcTerms",
                "detail": true,
                "requiresAuth": false,
                "title": title,
            },
            "top": {
                "component": "EcTop",
                "requiresAuth": false,
                "title": title,
            },
            "user/item": {
                "component": "ItemList",
                "requiresAuth": true,
                "title": title,
            },
            "warningNews": {
                "component": "EcNews",
                "detail": true,
                "requiresAuth": false,
                "title": title,
            },
        },
    },
    "/dashboard": {
        "name": "dashboard",
        "authentication": "AuthenticationFrame",
        "component": "DashboardFrame",
        "page": {
            "cloak": {
                "history": true,
            },
            "closedDate": {},
            "dayOff": {
                "component": {
                    "list": "DayOff",
                },
            },
            "eventNews": {},
            "item": {},
            "itemCategory": {},
            "news": {},
            "order": {
                "component": {
                    "detail": "DetailOrder",
                    "list": "ListOrigin",
                },
                "history": true,
            },
            "receptionTime": {
                "component": {
                    "list": "DetailReceptionTime",
                },
            },
            "recommendItem": {},
            "reservationTable": {
                "component": {
                    "list": "ReservationTable",
                },
            },
            "specialNews": {},
            "staff": {},
            "staffLevel": {},
            "terms": {},
            "topSellingItem": {},
            "user": {
                // "component": {
                //     "detail": "DetailOrigin",
                //     "list": "ListOrigin",
                // },
            },
            "user/:userId/item": {
                "history": true,
            },
            "warningNews": {},
            "workingShift": {
                "component": {
                    // "detail": "DetailOrigin",
                    "list": "WorkingShift",
                },
            },
            "workingRange": {
                "component": {
                    "detail": "WorkingRangeCalendar",
                    "list": "WorkingRangeCalendar",
                },
            },
        },
    },
};
