"use strict";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import Vue from "vue";
import Router from "vue-router";

import routerConfig from "@/routerConfig.js";

Vue.use(Router);

const routes = [];

// 複数のIDを配列として渡す
const setPropsId = (routes) => {
    const id = [];
    if (routes.params.userId) {
        id.push(routes.params.userId);
    }
    if (routes.params.updateId) {
        id.push(routes.params.updateId);
    }
    return id;
};

for (const path in routerConfig) {

    const data = routerConfig[path];
    const name = data.name;
    const page = data.page;

    // authentication
    routes.push({
        "path": path,
        "component": () => import("./layouts/" + data.authentication + ".vue"),
        "children": [
            {
                "path": "",
                "name": `${name}_root`,
                "component": () => import("./views/RootTransition"),
                "props": () => ({
                    "name": name,
                }),
                "meta": {
                    "requiresAuth": false,
                },
            },
            {
                "path": "signin",
                "name": `${name}_signin`,
                "component": () => import("./views/SignIn"),
                "props": () => ({
                    "name": name,
                }),
                "meta": {
                    "requiresAuth": false,
                },
            },
            {
                "path": "signout",
                "name": `${name}_signout`,
                "component": () => import("./views/SignOut"),
                "props": () => ({
                    "name": name,
                }),
                "meta": {
                    "requiresAuth": true,
                },
            },
            {
                "path": "signup",
                "name": `${name}_signup`,
                "component": () => import("./views/SignUp"),
                "props": () => ({
                    "name": name,
                }),
                "meta": {
                    "requiresAuth": name === "dashboard" ? true : false,
                },
            },
        ],
    });

    // page
    if (path === "/") {
        for (const key in page) {
            const routeData = {
                "path": `/${key}`,
                "component": () => import("./layouts/" + data.component + ".vue"),
                "children": [],
            };
            if (page[key].complete) {
                routeData.children.push({
                    "path": "create/complete",
                    "name": `${key}_create_complete`,
                    "component": () => import("./views/InputComplete.vue"),
                    "props": () => ({
                        "editMode": "create",
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": false,
                        "title": page[key].title,
                    },
                });
                routeData.children.push({
                    "path": "update/complete",
                    "name": `${key}_update_complete`,
                    "component": () => import("./views/InputComplete.vue"),
                    "props": () => ({
                        "editMode": "update",
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": false,
                        "title": page[key].title,
                    },
                });
            }
            if (page[key].confirm) {
                routeData.children.push({
                    "path": "create/confirm",
                    "name": `${key}_create_confirm`,
                    "component": () => import("./views/InputConfirm.vue"),
                    "props": () => ({
                        "editMode": "create",
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": false,
                        "title": page[key].title,
                    },
                });
                routeData.children.push({
                    "path": "update/confirm",
                    "name": `${key}_update_confirm`,
                    "component": () => import("./views/InputConfirm.vue"),
                    "props": () => ({
                        "editMode": "update",
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": false,
                        "title": page[key].title,
                    },
                });
            }
            if (page[key].input) {
                routeData.children.push({
                    "path": "create",
                    "name": `${key}_create_input`,
                    "component": () => import("./views/" + page[key].component + ".vue"),
                    "props": () => ({
                        "editMode": "create",
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": page[key].requiresAuth,
                        "title": page[key].title,
                    },
                });
                routeData.children.push({
                    "path": "update/:id",
                    "name": `${key}_update_input`,
                    "component": () => import("./views/" + page[key].component + ".vue"),
                    "props": routes => ({
                        "editMode": "update",
                        "id": routes.params.id,
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": page[key].requiresAuth,
                        "title": page[key].title,
                    },
                });
            }
            else {
                if (!page[key].detail) {
                    routeData.children.push({
                        "path": "",
                        "name": `${key}`,
                        "component": () => import("./views/" + page[key].component + ".vue"),
                        "props": () => ({
                            "name": key,
                        }),
                        "meta": {
                            "requiresAuth": page[key].requiresAuth,
                            "title": page[key].title,
                        },
                    });
                }
                else {
                    routeData.children.push({
                        "path": ":updateId",
                        "name": `${key}_detail2`,
                        "component": () => import("./views/" + page[key].component + ".vue"),
                        "props": routes => ({
                            "id": setPropsId(routes),
                            "name": key,
                        }),
                        "meta": {
                            "requiresAuth": page[key].requiresAuth,
                            "title": page[key].title,
                        },
                    });
                }
            }
            routes.push(routeData);
        }
    }
    if (path === "/dashboard") {
        for (const key in page) {
            const component = page[key].component;
            const history = page[key].history;
            const list = component ? component.list : "ListOrigin";
            const detail = component ? component.detail : "DetailOrigin";
            const routeData = {
                "path": `/${name}/${key}`,
                "component": () => import("./layouts/" + data.component + ".vue"),
                "children": [
                    {
                        "path": "",
                        "name": `${key}_list`,
                        "component": () => import("./views/" + list + ".vue"),
                        "props": routes => ({
                            "historyLink": history,
                            "id": setPropsId(routes),
                            "name": key,
                        }),
                        "meta": {
                            "requiresAuth": true,
                        },
                    },
                    {
                        "path": "create",
                        "name": `${key}_create`,
                        "component": () => import("./views/" + detail + ".vue"),
                        "props": routes => ({
                            "editMode": "create",
                            "id": setPropsId(routes),
                            "name": key,
                        }),
                        "meta": {
                            "requiresAuth": true,
                        },
                    },
                    {
                        "path": "update/:updateId",
                        "name": `${key}_update`,
                        "component": () => import("./views/" + detail + ".vue"),
                        "props": routes => ({
                            "editMode": "update",
                            "id": setPropsId(routes),
                            "name": key,
                        }),
                        "meta": {
                            "requiresAuth": true,
                        },
                    },
                    {
                        "path": "detail/:updateId",
                        "name": `${key}_detail`,
                        "component": () => import("./views/" + detail + ".vue"),
                        "props": routes => ({
                            "editMode": "detail",
                            "id": setPropsId(routes),
                            "name": key,
                        }),
                        "meta": {
                            "requiresAuth": true,
                        },
                    },
                ],
            };
            if (history) {
                routeData.children.push({
                    "path": "history",
                    "name": `${key}_history_list`,
                    "component": () => import("./views/ListOrigin.vue"),
                    "props": routes => ({
                        "history": true,
                        "id": setPropsId(routes),
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                });
                routeData.children.push({
                    "path": "history/detail/:updateId",
                    "name": `${key}_history_detail`,
                    "component": () => import("./views/" + detail + ".vue"),
                    "props": routes => ({
                        "editMode": "detail",
                        "history": true,
                        "id": setPropsId(routes),
                        "name": key,
                    }),
                    "meta": {
                        "requiresAuth": true,
                    },
                });
            }
            routes.push(routeData);
        }
    }

    // 存在しないページはrootへリダイレクト
    routes.push({
        "path": path === "/" ? "*" : `${path}/*`,
        "redirect": {
            "path": path,
        },
    });

}

if (process.env.VUE_APP_MODE === "develop") {
    console.log("開発モード");
    // setting update
    routes.push({
        "path": "/setting/update",
        "component": () => import("./layouts/UpdateFrame.vue"),
        "children": [
            {
                "path": "",
                "name": "settingUpdate",
                "component": () => import("./views/SettingUpdate.vue"),
                "props": () => ({
                    "name": "settingUpdate",
                }),
                "meta": {
                    "requiresAuth": true,
                },
            },
        ],
    });
}

// new router
const router = new Router({
    "mode": "history",
    "base": process.env.BASE_URL,
    "routes": routes,
    scrollBehavior(to) {
        if (!to.hash) {
            return {
                "x": 0,
                "y": 0,
            };
        }
    },
});

// auth check
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth) {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // 管理画面にアクセスしている
                if (location.pathname.indexOf("/dashboard") !== -1) {
                    // 管理者として登録があるか確認する
                    firebase.firestore().collection("administrators").doc(user.uid).get().then(response => {
                        // 登録があれば通す
                        if (response.data()) {
                            next();
                        }
                        // 登録がなければ管理画面の外へ
                        else {
                            next({
                                "path": "/signin",
                            });
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                }
                // 管理画面にアクセスしていない
                else {
                    next();
                }
            }
            else {
                next({
                    "path": location.pathname.indexOf("/dashboard") !== -1 ? "/dashboard/signin" : "/signin",
                });
            }
        });
    }
    else {
        next();
    }
});

export default router;
