"use strict";

import firebase from "firebase/app";
import Vue from "vue";

import App from "@/App.vue";
import router from "@/router.js";
import store from "@/store.js";

import "@/assets/styles/common.scss";

Vue.config.productionTip = false;

const firebaseConfig = {
    "apiKey": process.env.VUE_APP_FB_API_KEY,
    "authDomain": process.env.VUE_APP_FB_AUTH_DOMAIN,
    "projectId": process.env.VUE_APP_FB_PROJECT_ID,
    "storageBucket": process.env.VUE_APP_FB_STORAGE_BUCKET,
    "messagingSenderId": process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
    "appId": process.env.VUE_APP_FB_APP_ID,
    "measurementId": process.env.VUE_APP_FB_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

new Vue({
    router,
    store,
    "render": h => h(App),
}).$mount("#app");
